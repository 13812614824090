import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { Toaster, toast } from "react-hot-toast";

function NewsletterSection() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [email, setEmail] = useState('');
  const subtitleRef = useRef(null);

  function openModal(event) {
    event.preventDefault();
    if (email.trim() === '') {
      toast.error('Please enter your email address to subscribe.');
    } else if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
    } else {
      setIsOpen(true);
      setEmail('');
    }
  }

  function afterOpenModal() {
    if (subtitleRef.current) {
      subtitleRef.current.style.color = '#000';

    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (consentChecked) {
      toast.success('Subscribed successfully!');
      closeModal();
    } else {
      toast.error('Please agree to the terms and conditions.');
    }
  }
  
  const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  return (
    <footer id="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div >
                <div className="card-body mt-2">
                  {/* <h4 className="card-title">Join Our Newsletter</h4> */}
                  <h3 className="card-title"> Subscribe to receive the latest information on the Financial Markets.</h3>
                  {/* <p className="card-text text-center">Subscribe  our newsletter to recieve the latest updates and blogs</p> */}
                  <form id="newsletterForm" action="">
                    <input
                      type="email"
                      id='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // onChange={(e) => {
                      //   if (validateEmail(e.target.value)) {
                      //     setEmail(e.target.value);
                      //   } else {
                      //     console.log("Invalid email format");
                      //   }
                      // }}
                      required
                    />
                    <input
                      type="submit"
                      id="subscribeButton"
                      onClick={openModal}
                      value="Subscribe"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Toaster /> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Newsletter Subscription Modal"
      >
        <div className="modal-content">
          <span className="close" onClick={closeModal}>&times;</span>
          <div className="modal-body">
            <h5 ref={subtitleRef}>Moneytre Newsletter Disclaimer:</h5>
            <p>The content of this News Letter is protected by copyright and other intellectual
              property rights or similar rights, which unless indicated otherwise, are the property of
              Moneytre. No information published in this News Letter can be reproduced,
              distributed or published in whole or in part by any recipient, user or visitor for any
              purpose, without the prior express written consent of Moneytre.</p>
            <p>For the purpose of this News Letter, the term information, whether past or present,
              includes all and any views, opinions, recommendations, analysis, reports,
              suggestions and whatever content (collectively hereinafter referred to as
              &quot;Information&quot;) published by Moneytre, from time to time. All Information on the News
              Letter is believed to be up-to-date at the time it is posted and any content, form,
              structure, service offerings as also the location and jurisdiction of this News Letter, is
              subject to variation without any notice.</p>
            <p>No Information or service under this News Letter shall be relied upon as constituting
              personal investment advice. Information in this newsletter is for the use of the
              intended person only and it is not to be relied upon as authoritative or taken in
              substitution for the exercise of judgment by any recipient. Any recommendation,
              opinion or price targets discussed in this News Letter may not be achieved due to
              multiple risk factors including without limitation market volatility, sector volatility,
              corporate actions, macro or micro economic factors, the unavailability of complete
              and accurate information. Moneytre, its Directors, officers, employees and
              consultants accept no liability whatsoever for any direct or consequential loss or
              damage arising from any use of Information in any form or manner, contained in this
              News Letter.</p>
            <p>Information on this Newsletter is available for access to only those person
              (hereinafter referred to as &quot;Authorized Persons&quot;) who agree to comply with all
              registration, terms of use, subscription terms and other terms introduced Moneytre
              from time to time in order to comply with local laws or other regulatory requirements.
              Authorized Persons are those who are Resident Indians as defined under
              appropriate laws and not being less than 18 years of age as on date of access. It is
              the responsibility of persons to ensure there will be no breach of any laws or
              regulatory requirements by reason of their choosing to access information from this
              News Letter.</p>
            <p>There is a very high degree of risk involved in trading. Moneytre and all individuals
              associated with us assume no responsibilities for your trading and investment
              results. Your use of the trading observations is entirely at your own risk and it is your
              sole responsibility to evaluate the accuracy, completeness and usefulness of the
              information. You must assess the risk of any trade with your broker or any other
              qualified source and make your own independent decisions regarding any securities
              mentioned herein. Moneytre, its Directors, officers, employees and consultants
              accept no liability whatsoever for any direct or consequential loss or damage arising
              from any use of Information in any form or manner, contained in this News Letter.
              While all reasonable care has been taken to ensure that Information published by
              Moneytre in this news Letter is not untrue or misleading at the time of its publication,
              neither Moneytre nor its officers or employees makes any representation or warranty

              as to the accuracy or completeness of such Information. No liability is accepted for
              any loss whether direct or indirect, incidental or consequential, arising out of any of
              the research materials not being true and accurate.</p>
            <p>Affiliates of Moneytre may have a position or effect transactions in the securities
              described herein (or options thereon) and/or otherwise employ trading strategies that
              may be consistent or inconsistent with the provided strategies.</p>
            <label htmlFor="consentCheckbox">
              <input
                type="checkbox"
                id="consentCheckbox"
                checked={consentChecked}
                onChange={(e) => setConsentChecked(e.target.checked)}
              />
              I agree to the terms and conditions
            </label>
            <div className="buttonContainer">
              <div id="submitButton">
                <a className="btn-get-started justify-content-center" onClick={handleSubmit}>Submit</a>
              </div>
              <div>
                <a className="btn-get-started justify-content-center" onClick={closeModal}>Close</a>
              </div>
            </div>

          </div>
        </div>
      </Modal>
    </footer>
  );
}

export default NewsletterSection;

