import React from 'react'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from 'reactstrap';
  import { useState } from 'react';

function Faq3() {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
  return (
    <div>
    <div className='section-title'>
    <h5>Office <span>FAQ's</span></h5>
    </div>
    <br></br>
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">What are your opening hours?</AccordionHeader>
        <AccordionBody accordionId="1">
          <strong>This is the first item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="2">What days do you work?</AccordionHeader>
        <AccordionBody accordionId="2">
          <strong>This is the second item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="3"> Is your office open every day?</AccordionHeader>
        <AccordionBody accordionId="3">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="4">Do you work on weekends?</AccordionHeader>
        <AccordionBody accordionId="4">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  </div>
  )
}

export default Faq3