import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Topbar from '../Component/Topbar'
import Header from '../Component/Header'
import Footer from '../Component/Footer'

// import { blogs } from '../Api/'
import { FaRegShareSquare } from "react-icons/fa";
import axios from 'axios'
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
import { CgCopy } from 'react-icons/cg'



function Blogs() {

  const [blog, setBlog] = useState([]);

  // Server Url
  // const serverUrl = process.env.REACT_APP_SERVER_URL;
  // const clientUrl = process.env.REACT_APP_CLIENT_URL;


  // Fetch Blogs
  const fetchBlogs = async () => {
    try {
      const response = await axios.get('http://54.163.28.8:5001/mt-backend-live/blog/get_all_blog');
      setBlog(response.data.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchBlogs();
  }, []);


  const [currentBlogId, setCurrentBlogId] = useState(null);

  const handleShow = (blogId) => {
    setCurrentBlogId(blogId);
  };

  // Share Blog Url
  const shareUrl = `http://54.163.28.8:5001/mt-backend-live/blogdetails/${currentBlogId}`;
  const randomReadTime = Math.floor(Math.random() * 10) + 1;
  return (
    <div className='main'>
      <Topbar />
      <Header />
      {/* <main id="main">
        <section className="breadcrumbs">
          <div className="container">

            <ol>
              <li> <Link to={'/'}><a>Home</a></Link>  </li>
            </ol>


          </div>
        </section>
        <section id="blog" className="blog">

          <div className="container" data-aos="fade-down">
            <div className="section-title">
              <h2>Blogs</h2>

            </div>
            <br></br>
            <div className="row gy-4 posts-list">
            

              {blog.map((blog) => {
                if (!blog) {
                  return <div id="preloader"></div>
                 }
                return (
                  <>

                    <div className="col-xl-4 col-lg-6">
                      <article className="card-border">
                        <div className="post-img">
                         
                          <img src={blog.image} alt="" className="img-fluid" style={{ width: "100%" }} />
                        </div>
                       
                        <h2 className="title">
                          <Link to={`/blogdetails/${blog.id}`}>{blog.title}</Link>
                        </h2>
                        <p className="post-category">{blog.type_blog}</p>

                        <div className="d-flex align-items-center justify-content-between">
                          <div className='d-flex'>
                            <img src="assets/img/blog/hetal.jpeg" alt="" className="img-fluid post-author-img rounded-circle" style={{
                              width: '12%',
                              height: '10%',
                              objectFit: 'cover',
                              objectPosition: 'center'
                            }} />
                            <div className="post-meta">
                              <p className="post-author">Hetal shah</p>
                              <div className="post-date">
                                <div className='d-flex flex-justify-end item-center'>
                                  <time style={{ fontSize: '12px', marginTop: '2px' }} dateTime={new Date(blog.dateandtime).toISOString()}>
                                    {new Date(blog.dateandtime).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                                  </time>
                                  <p className="read-time" style={{ marginLeft: '10px', marginTop: '1px', fontSize: '12px' ,marginBottom:'2px'}}>{5} min read</p>
                                </div>
                              </div>


                         
                            </div>
                          </div>

                        
                          <div className="post-share">
                            <FaRegShareSquare data-bs-toggle="modal" data-bs-target="#ShareModal" style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => handleShow(blog.id)} />
                          </div>

                        </div>

                      
                        <div className="modal fade" id="ShareModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'none' }} >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title text-danger" id="exampleModalLabel">Share</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body d-flex flex-wrap justify-content-around px-5 py-4">
                                <FacebookShareButton className="my-2"
                                  url={shareUrl}>
                                  <FacebookIcon size={40} round={true} />
                                </FacebookShareButton>

                                <WhatsappShareButton className="my-2"
                                  url={shareUrl}>
                                  <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>

                                <LinkedinShareButton className="my-2"
                                  url={shareUrl}>
                                  <LinkedinIcon size={40} round={true} />
                                </LinkedinShareButton>

                                <EmailShareButton className="my-2"
                                  url={shareUrl}>
                                  <EmailIcon size={40} round={true} />
                                </EmailShareButton>

                                <TelegramShareButton className="my-2"
                                  url={shareUrl}>
                                  <TelegramIcon size={40} round={true} />
                                </TelegramShareButton>

                                <TwitterShareButton className="my-2"
                                  url={shareUrl}>
                                  <TwitterIcon size={40} round={true} />
                                </TwitterShareButton>
                                <CgCopy size={40} className="my-2" onClick={() => navigator.clipboard.writeText(shareUrl)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </>
                )
              })
              }
            </div>
          </div>
        </section >
        <br></br>
      </main > */}

<main id="main">

    <div className="breadcrumbs" data-aos="fade-in">
      <div className="container">
        <ol>
          <li> <Link to={'/'}><a>Home</a></Link>  </li>
        
        </ol>
    
      </div>
    </div>
    <section id="blog" className="blog">

        <div className="container" data-aos="fade-up" data-aos-delay="100">
  
          <div className="row gy-4 posts-list">
  
          
  
            <div className="col-xl-4 col-lg-6">
              <article>
  
                <div className="post-img">
                  <img src="assets/img/blog/blog-4.jpg" alt="" className="img-fluid"/>
                </div>
  
                {/* <p className="post-category">Trade</p> */}
  
                <h2 className="title">
                  {/* <a href="blogdetails1.html">Why a trade plan is necessary</a> */}
                  <NavLink to="/blogdetails1">Why a trade plan is necessary</NavLink>
                </h2>
  
                <div className="d-flex align-items-center">
                  <img src="assets/img/blog/hetal.jpeg" alt="" className="img-fluid post-author-img flex-shrink-0"/>
                  <div className="post-meta">
                    <p className="post-author">Hetal Shah</p>
                    <p className="post-date">
                      <time datetime="2022-01-01">Jun 30, 2022</time>
                    </p>
                  </div>
                </div>
  
              </article>
            </div>
  
            <div className="col-xl-4 col-lg-6">
              <article>
  
                <div className="post-img">
                  <img src="assets/img/blog/blog-5.jpg" alt="" className="img-fluid"/>
                </div>
  
                {/* <p className="post-category">Compete</p> */}
  
                <h2 className="title">
               
                  <NavLink to="/blogdetails2">Become a competent trader</NavLink>
                </h2>
  
                <div className="d-flex align-items-center">
                  <img src="assets/img/blog/hetal.jpeg" alt="" className="img-fluid post-author-img flex-shrink-0"/>
                  <div className="post-meta">
                    <p className="post-author">Hetal Shah</p>
                    <p className="post-date">
                      <time datetime="2022-01-01">Jan 30, 2022</time>
                    </p>
                  </div>
                </div>
  
              </article>
            </div>
  
  
         
  
          </div>
  
          
  
        </div>
  
      </section>
    

</main>
  
      <Footer />
    </div >
  )
}

export default Blogs