
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Topbar from '../Component/Topbar'
import Header from '../Component/Header'
import Footer from '../Component/Footer'

import Faq1 from '../Component/Faq1';
import Faq2 from '../Component/Faq2';
import Faq3 from '../Component/Faq3';


function Faqs() {
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <div className="main">
      <Topbar />
      <Header />
      <main id="main" >
        <div class="breadcrumbs">
          <div class="container">
            <ol>
              <li> <Link to={'/'}><a>Home</a></Link>  </li>
            </ol>


          </div>
        </div>

        <section id="about" class="about">
          <div class="container" data-aos="fade-down">
            <div class="section-title">
              <h2>FAQ's</h2>

            </div>
            <Faq1 />
          
            {/* Engagement FaQ end */}
            {/* Payment FAQ */}
            <Faq2 />  

          
            {/* Payment FAQ end */}
            {/* Office FAQ Starts */}
            <Faq3 />
      
            {/* Office FAQ End */}
          </div>
        </section>
      </main>
      <br></br>
      <Footer />
    </div>
  )
}

export default Faqs