import React from 'react'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from 'reactstrap';
  import { useState } from 'react';

function Faq1() {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
  return (
    <div>
    <div className='section-title'>
    <h5>Engagement <span>FAQ's</span></h5>
    </div>
    <br></br>
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">Do you charge for the first meeting?</AccordionHeader>
        <AccordionBody accordionId="1">
          <strong>This is the first item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="2"> How do I engage you as a consultant?</AccordionHeader>
        <AccordionBody accordionId="2">
          <strong>This is the second item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="3"> Can I meet you with video conferencing etc?</AccordionHeader>
        <AccordionBody accordionId="3">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="4">Will you keep our data secure?</AccordionHeader>
        <AccordionBody accordionId="4">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="5">Do you use secure process of suggestions?</AccordionHeader>
        <AccordionBody accordionId="5">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="6">What if I am not happy with your suggestions?</AccordionHeader>
        <AccordionBody accordionId="6">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="7">Who does all the work before coming up with the recommendations?</AccordionHeader>
        <AccordionBody accordionId="7">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="8">Are you a Broker and do you suggest any broking house?</AccordionHeader>
        <AccordionBody accordionId="8">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="9"> Do you offer training in the ways of the Capital Markets?</AccordionHeader>
        <AccordionBody accordionId="9">
          <strong>This is the third item&#39;s accordion body.</strong>
          You can modify any of this with custom CSS or overriding our default
          variables. It&#39;s also worth noting that just about any HTML can
          go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionBody>
      </AccordionItem>
     
    </Accordion>
  </div>
  )
}

export default Faq1