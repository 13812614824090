import React from 'react'
import Topbar from '../Component/Topbar'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'

function Products() {
  return (
    <div className="main">
      <Topbar />
      <Header />
      <main id="main" >
        <div class="breadcrumbs">
          <div class="container">
            <ol>
              <li> <Link to={'/'}><a>Home</a></Link>  </li>
            </ol>


          </div>
        </div>

        <section id="about" class="about">
          <div class="container" data-aos="fade-down">
            <div class="section-title">
              <h2>Products</h2>

            </div>
          </div>
        </section>
      </main>
      <br></br>
      <Footer />
    </div>
  )
}

export default Products