import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from "react-hot-toast";

function ContactUsSection() {
  // Server Url
  // const serverUrl ="http://54.163.28.8:5001/mt-backend-live";

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [message, setMessage] = useState('');

 
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Client-side mobile number validation
    if (!/^\d{10}$/.test(mobileNumber)) {
      toast.error("Please enter a valid 10-digit mobile number");
      return;
    }
  
    const formData = {
      c_name: name,
      c_email: email,
      c_subject: subject,
      c_mobile_no: mobileNumber,
      c_message: message
    };
  
    try {
      const response = await axios.post('http://54.163.28.8:5001/mt-backend-live/contactus/create_contactus', formData);
  
      if (response.status === 201) {
        toast.success("Your message has been sent. Thank you!");
        console.log(response);
      } else {
        toast.error("Failed to send message. Please try again later.");
        console.log(response);
      }
    } catch (error) {
      console.error('Error:', error.response.data.message);
      
      
      toast.error("Failed to send message. Please try again later.");
    } finally {
      // Clear input fields regardless of the response status or any errors
      setName('');
      setEmail('');
      setSubject('');
      setMobileNumber('');
      setMessage('');
    }
  };
  
  return (
    <div className='main'>
      <main id="main">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-down">
            <Toaster />
            <div className="section-title">
              <h2>Contact Us</h2>
              {/* <h3>Feel free to <a href="#"><span>Connect</span></a></h3> */}
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-5 d-flex align-items-stretch">
                {/* Address info */}

             
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>201, Vilux Redefine, Deccan Gymkhana, Pune 411004.</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>hkot3@yahoo.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 74109 12161</p>
                </div>


                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.23120153327!2d73.8371157750378!3d18.518451382574657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfa899663d31%3A0x8b17e65d6bc36d04!2sVilux%20Redefine!5e0!3m2!1sen!2sin!4v1705393118592!5m2!1sen!2sin"
                  frameborder="0" style={{ border: 0, width: '100%', height: '300px' }} allowfullscreen></iframe>

              </div>

             

              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form onSubmit={handleSubmit} role="form" className="php-email-form">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Name</label>
                      <input type="text" name="name" className="form-control" id="name" value={name} onChange={e => setName(e.target.value)} required />
                    </div>
                    <div className="form-group col-md-6 mt-3 mt-md-0">
                      <label htmlFor="email">Your Email</label>
                      <input type="email" className="form-control" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="subject">Subject</label>
                      <input type="text" name="Subject" className="form-control" id="Subject" value={subject} onChange={e => setSubject(e.target.value)} required />
                    </div>
                    <div className="form-group col-md-6 mt-3 mt-md-0">
                      <label htmlFor="mobileNumber">Mobile Number</label>
                      <input type="text" className="form-control" name="mobileNumber" id="mobileNumber" value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} required />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control" name="message" rows="8" value={message} onChange={e => setMessage(e.target.value)} required></textarea>
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="text-center"><button type="submit">Submit</button></div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default ContactUsSection;




// import React, { useState } from 'react'
// import axios from 'axios'
// import { Toaster, toast } from "react-hot-toast";

// function ContactUsSection() {

//   // Server Url
//   const serverUrl = process.env.REACT_APP_SERVER_URL;

//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [subject, setSubject] = useState('');
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [message, setMessage] = useState('');

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const formData = { c_name: name, c_email: email, c_subject: subject, c_mobile_no: mobileNumber, c_message: message };
//     try {
//       const response = await axios.post(`${serverUrl}/contactus/create_contactus`, formData);

//       if (response) {
//         toast.success("Your message has been sent. Thank you!");
//       }else{
//         toast.error("Please fill right details")
//       }

//       // Clear input fields
//       setName('');
//       setEmail('');
//       setSubject('');
//       setMobileNumber('');
//       setMessage('');

//     } catch (error) {
//       console.error('Error:', error.response.data.message);
//       toast.error(error.response.data.message);
//     }
//   };
//   return (

//     <div classNameName='main'>
    
//     <main id="main">
    
//       <section id="contact" className="contact">
//         <div className="container" data-aos="fade-down">

//           <Toaster />

//           <div className="section-title">

//             <h2>Contact Us</h2>

//           </div>

//           <div className="row">

            // <div className="col-lg-5 d-flex align-items-stretch">
            //   <div className="info">
            //     <div className="address">
            //       <i className="bi bi-geo-alt"></i>
            //       <h4>Location:</h4>
            //       <p>201, Vilux Redefine, Deccan Gymkhana, Pune 411004.</p>
            //     </div>

            //     <div className="email">
            //       <i className="bi bi-envelope"></i>
            //       <h4>Email:</h4>
            //       <p>hkot3@yahoo.com</p>
            //     </div>

            //     <div className="phone">
            //       <i className="bi bi-phone"></i>
            //       <h4>Call:</h4>
            //       <p>+91 74109 12161</p>
            //     </div>


            //     <iframe
            //       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.23120153327!2d73.8371157750378!3d18.518451382574657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfa899663d31%3A0x8b17e65d6bc36d04!2sVilux%20Redefine!5e0!3m2!1sen!2sin!4v1705393118592!5m2!1sen!2sin"
            //       frameborder="0" style={{ border: 0, width: '100%', height: '300px' }} allowfullscreen></iframe>

            //   </div>

            //  </div>

//             <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
//               <form onSubmit={handleSubmit} role="form" className="php-email-form">
//                 <div className="row">
//                   <div className="form-group col-md-6">
//                     <label for="name">Your Name</label>
//                     <input type="text" name="name" className="form-control" id="name" value={name} onChange={e => setName(e.target.value)} required />
//                   </div>
//                   <div className="form-group col-md-6 mt-3 mt-md-0">
//                     <label for="name">Your Email</label>
//                     <input type="email" className="form-control" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="form-group col-md-6">
//                     <label for="name">Subject</label>
//                     <input type="text" name="Subject" className="form-control" id="Subject" value={subject} onChange={e => setSubject(e.target.value)} required />
//                   </div>
//                   <div className="form-group col-md-6 mt-3 mt-md-0">
//                     <label for="name">Mobile Number</label>
//                     <input type="name" className="form-control" name="Mobile Number" id="Mobile Number" value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} required />
//                   </div>
//                 </div>
//                 <div className="form-group mt-3">
//                   <label for="name">Message</label>
//                   <textarea className="form-control" name="message" rows="8" value={message} onChange={e => setMessage(e.target.value)} required></textarea>
//                 </div>
//                 <br></br>
//                 <br></br>
//                 <br></br>
//                 {/* <div className="my-3">
//                   <div className="loading">Loading</div>
//                   <div className="error-message"></div>
//                   <div className="sent-message">Your message has been sent. Thank you!</div>
//                 </div> */}
//                <div className="text-center"><button type="submit">Submit</button></div>
//               </form>
//             </div>

//           </div>

//         </div>
//       </section>
//     </main>

//   </div>
 
//   )
// }

// export default ContactUsSection