import React from 'react'
import Topbar from '../Component/Topbar'
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'


function BlogDetails1() {
    return (
        <div className='main'>
            <Topbar />
            <Header />

            <main id="main">

                <div data-aos="fade" className="page-title">

                    <div className="container">

                    </div>

                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li> <Link to={'/'}><a>Home</a></Link>  </li>
                                <li> <Link to={'/blogs'}><a>Blog</a></Link>  </li>
                            </ol>

                        </div>
                    </nav>
                </div>
                <section id="blog-details" className="blog-details">

                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-title">

                            <h2>Blog Details</h2>

                        </div>
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-md-10 mt-5 mt-lg-0 d-flex align-items-stretch">
                                <div className="container">
                                    <article className="article">

                                        <div >
                                            <img src="assets/img/blog/blog-1.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">Why Trade Plan is necessary?</h2>

                                        <div className="meta-top">
                                            <ul>
                                                <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blogdetails1.html">Hetal
                                                    Shah</a></li>
                                                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blogdetails1.html"><time
                                                    datetime="2020-01-01">Jan 1, 2022</time></a></li>
                                                <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blogdetails1.html">0
                                                    Comments</a></li>
                                            </ul>
                                        </div>

                                        <div className="content">
                                            <p>Hello Market Participants,</p>
                                            <p>
                                                I take the liberty of emphasizing the crucial role of a well-constructed trading plan in
                                                achieving success in the financial markets.
                                            </p>
                                            <p>In this article I will highlight several key points:</p>
                                            <p>
                                                <b>1. Simplicity vs. Complexity: </b>The trading plan should strike a balance between simplicity
                                                and complexity, tailored to the trader&#39;s preferences. While a plan needs to be
                                                comprehensive, it should also be practical and applicable in real-market situations.
                                            </p>
                                            <p>
                                                <b> 2. Objectives of a Trading Plan: </b>A trading plan serves to keep the trader focused and
                                                calm during trades. Successful traders engage in strategic thinking before entering a
                                                trade, allowing them to remain composed throughout the trade&#39;s duration.
                                            </p>
                                            <p>
                                                <b> 3. Dynamic vs. Static Plans:</b> While some argue for the dynamic nature of trading plans,
                                                you advocate for a static approach. The reasoning is that a well-constructed plan should
                                                already account for various market conditions, including trending and non-trending
                                                markets, aligning with the trader&#39;s knowledge, style, and risk tolerance.
                                            </p>
                                            <p>
                                                <b> 4. Importance of Mentorship: </b>Constructing a trading plan can be a challenging and time-
                                                consuming process. Having an experienced mentor can significantly expedite the
                                                learning curve and provide invaluable insights derived from years of market experience.
                                            </p>
                                            <p>
                                                <b> 5. Result-Oriented and Stress-Free Trading:</b> A well-conceived trading plan transforms
                                                trading into a more objective, less stressful, and unemotional endeavor. It acts as a
                                                guiding partner that helps the trader make informed decisions and navigate the markets
                                                successfully.
                                            </p>
                                            <p>
                                                <h5> <b>In conclusion,</b></h5>
                                                &quot;Plan Your Trade and Trade Your Plan&quot; encapsulates the essence of disciplined and
                                                systematic trading. A solid trading plan is a cornerstone for success in the dynamic and
                                                unpredictable world of financial markets.
                                            </p>
                                        </div>


                                    </article>
                                    <div class="comments " id="commnets">
                                        <div class="reply-form">

                                            <h4>Comments</h4>

                                            <form action="">

                                                <div class="row">
                                                    <div class="col form-group">
                                                        <input name="email" type="text" class="form-control" placeholder="Your email" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col form-group">
                                                        <textarea name="comment" class="form-control" placeholder="Your Comment*"></textarea>
                                                    </div>
                                                </div>

                                                <div class="text-center">
                                                    <button type="submit" class="btn btn-primary d-flex flex justify-content-start">Post Comment</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>




                    </div>

                </section>

            </main>
            <Footer />


        </div>
    )
}

export default BlogDetails1