import React from 'react';
import Header from '../Component/Header';
import Topbar from '../Component/Topbar';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom'


function Knowledge() {

  const navigateToWebsite = () => {
    // Change the window location to another website
    window.location.href = 'https://primeanalytics.in/';
  };

  return (
    <div className='main'>
      <Topbar />
      <Header />
      <main id="main">

        <div class="breadcrumbs" data-aos="fade-in">
          <div class="container">

            <ol>
              <li> <Link to={'/'}><a>Home</a></Link>  </li>
            </ol>


          </div>
        </div>
        <br></br>
        <div class="container" data-aos="fade-in">
          <div class="section-title">
            <h2> Knowledge</h2>
            <section id="contact" className="contact">
              <div className="container" data-aos="fade-down">
                <br/>
                <div className='d-flex flex' >
                  <span style={{marginRight:'1%'}}>prime Analytics : </span>
                  <a href="https://primeanalytics.in/" target="_blank" rel="noopener noreferrer">https://primeanalytics.in</a>
                </div>
                <br/>
                <div className='d-flex flex'  >
                  <span style={{marginRight:'1%'}}>Indrazith Shantharaj : </span>
                  <a href=" https://indrazith.com/ " target="_blank" rel="noopener noreferrer"> https://indrazith.com/ </a>
                </div>
                <br/>

      
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Knowledge;
